import { Router } from "preact-router";
import { useState, useEffect, useContext } from "preact/hooks";

import Header from "./header";
import ReviewsList from "../routes/reviews";
import { getReviewParams, getReviews } from "../utils/api";
import { StoresConsumer, StoresProvider, useStores } from "../contexts/StoresContext";
import {
  TranslateContext,
} from "@denysvuika/preact-translate";


function MyApp({changePage}) {
  const {t} = useContext(TranslateContext)

  return (
    <StoresConsumer>
      {(store) => {
        return (
          <>
            <Header stores={store} />
            <h3>
              {t("reviews_from_our_clients_following_booking")}
            </h3>
            <Router>
              <div default>
                <ReviewsList stores={store} changePage={changePage} />
              </div>
          </Router>
          </>
        );
      }}
    </StoresConsumer>
  );
}

const App = () => {
  const [store, setData] = useState(useStores());
  useEffect(() => {
    changePage()
  }, []);
  const {setLang} = useContext(TranslateContext)

  let availableLocales = [
    "cs",
    "de",
    "el",
    "en",
    "es",
    "fr",
    "it",
    "ja",
    "nl",
    "ru",
    "zh",
  ];

  const changePage = (page=1) => {
    Promise.all([getReviewParams(), getReviews(page)]).then(
      ([paramsResponse, reviewsResponse]) => {
        setLang(availableLocales.includes(paramsResponse.lang) ? paramsResponse.lang : "fr")
        setData({
          paramsData: paramsResponse,
          reviewsData: reviewsResponse,
        });
        window.scrollTo(0, 0);
      }
    );
  }

  return (
    <div id="app" class="s--reviews container">
      <div class="reviews-wrap">
          <StoresProvider value={store}>
            <MyApp changePage={changePage} />
          </StoresProvider>
      </div>
    </div>
  );
};

export default App;
