import './style';
import App from './components/app';
import { TranslateProvider } from '@denysvuika/preact-translate';

const MyApp = () => (
    <TranslateProvider>
      <App />
    </TranslateProvider>
);

export default MyApp;
