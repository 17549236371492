import { useContext } from "preact/hooks";
import {
  TranslateContext,
} from "@denysvuika/preact-translate";

const Header = ({ stores }) => {
  const header = stores.paramsData;
  const {t} = useContext(TranslateContext)
  return (
    <>
      {header ? (
        <>
        <div class="reviews__global reviews__global--top">
          <div class="reviews-global__average">
            <div>
              <span class="reviews-global__note">{header.average_global.toFixed(1)}</span>
              <span class="reviews-global__total">
              /5</span>
            </div>
            <div>
              <div class="stars">
                <span
                  class="stars__filled"
                  style={{
                    width: `${20 * header.average_global}%`,
                  }}
                />
              </div>
              <p class="reviews-global__number">
                {t('global mark')} — <span>{header.reviews_count} {t('review(s)')}</span>
              </p>
            </div>
          </div>

          <div class="reviews-global__details">
            <div class="reviews-global__criterion">
              <span>{t('service')}</span>
              <div class="stars">
              <span
                  class="stars__filled-black"
                  style={{
                    width: `${20 * header.average_service}%`,
                  }}
                />
              </div>
            </div>
            <div class="reviews-global__criterion">
              <span>{t('ambience')}</span>
              <div class="stars">
              <span
                  class="stars__filled-black"
                  style={{
                    width: `${20 * header.average_menu}%`,
                  }}
                />
              </div>
            </div>
            <div class="reviews-global__criterion">
              <span>{t('menu')}</span>
              <div class="stars">
              <span
                  class="stars__filled-black"
                  style={{
                    width: `${20 * header.average_menu}%`,
                  }}
                />
              </div>
            </div>
            <div class="reviews-global__criterion">
              <span>{t('quality_price')}</span>
              <div class="stars">
              <span
                  class="stars__filled-black"
                  style={{
                    width: `${20 * header.average_value_for_money}%`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        </>
      ) : null}
    </>
  );
};

export default Header;
