import { createContext } from "preact";
import { useContext } from "preact/hooks";
import Store from '../utils/store';

const StoresContext = createContext(Store);

const StoresProvider = StoresContext.Provider;
const StoresConsumer = StoresContext.Consumer;
const useStores = () => useContext(StoresContext);

export { StoresConsumer, StoresProvider, useStores };

export default StoresContext;