const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const RESTAURANTID = params.rid ? params.rid : window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

export { getReviews, getReviewParams };

function getReviewParams() {
  const url = `https://api.zenchef.com/api/v1/restaurants/${RESTAURANTID}/reviewParams`;
  return fetch(url, { method: "GET" }).then((response) =>
    response.json()
  );
}

function getReviews(page = 1) {
  const url = `https://api.zenchef.com/api/v1/restaurants/${RESTAURANTID}/reviews?page=${page}`;
  return fetch(url, { method: "GET" }).then((response) =>
    response.json()
  );
}
